<template>
  <dashboard-page type="realm"></dashboard-page>
</template>

<script>

import DashboardPage from '@/app/pages/DashboardPage'

export default {
  name: 'GlobalDashboardPage',
  components: { DashboardPage }
}
</script>

<style scoped>

</style>
